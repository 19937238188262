<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="order-info" v-if="orderDetail">
        <div class="order-info-box">
          <div class="info">
            <div class="control">
              <div class="label">台位</div>
              <div class="value">
                {{ orderDetail.tableName }}
              </div>
            </div>

            <div class="control">
              <div class="label">时间</div>
              <div class="value">
                {{ $filters.formatDate(orderDetail.bookTime, "MM-DD HH:mm") }}
              </div>
            </div>

            <div class="control">
              <div class="label">人数</div>
              <div class="value">{{ orderDetail.peopleCount }}</div>
            </div>

            <div class="control">
              <div class="label">订单金额</div>
              <div class="value">¥ <strong style="color:#f50">{{ orderDetail.orderAmount }}</strong></div>
            </div>

            <div class="control">
              <div class="label">菜品金额</div>
              <div class="value">¥ <strong style="color:#f50">{{ orderDetail.caijinAmount }}</strong></div>
            </div>
          </div>

          <div class="items">

            <div class="item" v-for="(v, k) in orderItems2" :key="k">
              <div>
                <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
                <a-tag color="orange" v-if="v.state === 1">赠菜</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems3" :key="k">
              <div>
                <a-tag color="blue">酒水</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems4" :key="k">
              <div>
                <a-tag color="red">损耗</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems5" :key="k">
              <div>
                <a-tag color="red" v-if="v.targetOrderId === orderId">{{
                  v.fromTableName
                }}</a-tag>
                <a-tag color="red" v-else>{{ v.toTableName }}</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                <div v-if="v.targetOrderId !== orderId">
                  ¥<span>{{ v.sellingPrice }}</span>
                </div>
                <div v-else>¥0</div>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems" :key="k">
              <div>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <div class="footer" v-if="orderDetail">
      <div class="price">订单金额：<span>¥</span> <strong style="color:#f50">{{ orderDetail.orderAmount }}</strong></div>

      <a-spin :spinning="loading" v-if="orderDetail && orderDetail.orderStatus === 2">
        <div class="pay-btn" @click="onPay">立即支付</div>
      </a-spin>
      <div v-else class="pay-status">
        <a-tag v-if="orderDetail.orderStatus === 5" color="#f5222d">退订</a-tag>
        <a-tag v-if="orderDetail.orderStatus === 7" color="#52c41a">结算完成</a-tag>
        <a-tag v-if="orderDetail.orderStatus === 8" color="#fa8c16">退款</a-tag>
        <a-tag v-if="orderDetail.orderStatus === 10" color="#ccc">作废</a-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { PullRefresh } from 'vant'
import 'vant/es/pull-refresh/style'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import {} from 'ant-design-vue'
import form from '@/mixins/form'
import { OrderClass } from '@/apis/order'
import { WxGZHCLass } from '@/apis/wxgzh'

import defaultConfig from '@/config/default.config'
import { isIos, wechatConfig } from '@/utils'
import storage from 'store'

const order = new OrderClass()
const wxgzh = new WxGZHCLass()
export default defineComponent({
  components: {
    [PullRefresh.name]: PullRefresh,
    CloseOutlined
  },
  mixins: [form],
  setup () {
    const loading = ref(false)
    const spinning = ref(false)
    const refreshing = ref(false)
    const orderDetail = ref()
    const orderItems = ref([])
    const orderItems2 = ref([])
    const orderItems3 = ref([])
    const orderItems4 = ref([])
    const orderItems5 = ref([])
    const orderId = ref('')

    const onGetOrder = () => {
      spinning.value = true
      order.detail(orderId.value).then((resp) => {
        orderDetail.value = resp

        // 必点品
        orderItems.value = resp.orderItems.filter((x) => x.dish.isMust)
        // 加菜或赠菜
        orderItems2.value = resp.orderItems.filter((x) => x.type === 4)
        // 酒水
        orderItems3.value = resp.orderItems.filter((x) => x.type === 2)
        // 损耗
        orderItems4.value = resp.orderItems.filter((x) => x.type === 3)
        orderItems5.value = resp.orderItems.filter((x) => x.type === 5)

        spinning.value = false
        refreshing.value = false
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      onGetOrder()
    }

    return {
      spinning,
      refreshing,
      orderDetail,
      orderItems,
      orderItems2,
      orderItems3,
      orderItems4,
      orderItems5,
      orderId,
      loading,
      onGetOrder,
      onRefresh
    }
  },

  created () {
    window.onbeforeunload = (e) => {
      const url = defaultConfig.host + this.$route.path
      storage.set('wxConfigURL', url)
    }

    const id = this.$route.params.id
    this.orderId = id

    this.onGetOrder()

    if (!isIos()) {
      wechatConfig()
    } else {
      const url = storage.get('wxConfigURL')
      if (url.indexOf('login') === -1 && url.indexOf('regist') === -1) {
        wechatConfig(url)
      }
    }
  },

  mounted () {},

  methods: {
    onPay () {
      this.loading = true
      const orderId = this.orderId

      const _this = this
      this.$confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确认支付？'
        ),
        onCancel () {
          _this.loading3 = false
        },
        onOk () {
          wxgzh.pay(orderId).then(resp => {
            // eslint-disable-next-line no-undef
            wx.chooseWXPay({
              timestamp: resp.timeStamp,
              nonceStr: resp.nonceStr,
              package: resp.package,
              signType: resp.signType,
              paySign: resp.paySign,
              success: function (res) {
                // 支付成功后的回调函数
                _this.onGetOrder()
                _this.loading3 = false
              }
            })
          })
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
.order-info {
  width: 100%;
  height: 100vh;

  .order-info-box {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .info {
    background: #d3eaff;
    padding: 0.2rem;

    .control {
      display: flex;
      justify-content: space-between;
      height: 30px;
      align-items: center;
      .label {
        color: #999;
      }
      .value {
        color: #333;
        font-weight: 600;
      }
    }
  }

  .item {
    padding: 5px;
    font-size: 0.24rem;
    height: 0.8rem;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;

    .count {
      font-size: 0.2rem;
    }

    .item-right {
      font-size: 0.24rem;
      color: #f50;
      span {
        font-size: 0.3rem;
        padding-left: 2px;
      }
    }

    &.title {
      background: #f8f8f8;
      border-bottom: 3px solid #ebebeb;
      font-size: 0.28rem;
      color: #333;
      font-weight: 600;
      padding: 5px 10px;
    }
  }

  .items {
    padding: 0.2rem;
  }
}

.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  background: #fff;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  .price{
    font-size: 0.3rem;
    line-height: 1rem;
    padding-left: 0.2rem;
    strong{
      font-size: 0.4rem;
      color: #f50;
    }
  }
  .pay-btn{
    width: 2rem;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    background: #096dd9;
    color: #fff;
    font-size: 0.3rem;
  }
  .pay-status{
    width: 2rem;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
  }
}
</style>
